import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { editAction, removeAction } from '@commons/draggable-list';
import { NestedListItem } from '@commons/nested-list';
import { statusTag } from '@commons/tag/specific-tags/status';
import { MENU_ROUTING } from '@features/partners/builder/settings/menu/routes/menu.routes';
import { Tab } from '@models/commons/tabs';
import {
  DESKTOP_MENU_TYPE,
  MAIN_NAVIGATION_TYPE,
  MenuType,
  NavigationItem,
  NavigationItemSchedule,
  NavigationType,
  PlanGroupMenuResponse,
  SECONDARY_NAVIGATION_TYPE,
} from '@models/partner/menu/plan-group-menu-response';
import { PlanGroup } from '@models/partner/plans.interface';
import { MenuWebservice } from '@webservices/menu/menu.webservice';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuListService {
  readonly #menuWebservice = inject(MenuWebservice);
  readonly #updatedMenu$ = new BehaviorSubject<PlanGroupMenuResponse | null>(null);

  // PARTNER ID
  readonly #partnerId$ = new ReplaySubject<string>(1);
  readonly setPartnerId = (partnerId: string) => {
    if (typeof partnerId !== 'string') return;
    this.#partnerId$.next(partnerId);
  };

  // PLANGROUP ID
  readonly #currentPlanGroupId$ = new ReplaySubject<string>(1);
  public setCurrentPlanGroupId(planGroupId: string) {
    if (typeof planGroupId !== 'string') return;
    this.#updatedMenu$.next(null);
    this.#currentPlanGroupId$.next(planGroupId);
  }
  public readonly currentPlanGroupId = toSignal(this.#currentPlanGroupId$, { initialValue: null });

  public readonly currentPlanGroupLabel = computed(() => {
    const currentPlanGroupId = this.currentPlanGroupId();
    if (currentPlanGroupId === null) return null;
    return this.planGroups().find(({ id }) => id === currentPlanGroupId)?.name ?? null;
  });

  // PLANGROUPS;
  readonly #planGroups$ = new ReplaySubject<Array<PlanGroup>>(1);
  public setPlanGroups = (planGroups: Array<PlanGroup>) => {
    this.#planGroups$.next(planGroups);
  };
  public readonly planGroups = toSignal(this.#planGroups$, { initialValue: [] });

  //MENU TYPE
  readonly #menuType$ = new ReplaySubject<MenuType>(1);
  public setMenuType = (menuType: MenuType) => {
    this.#menuType$.next(menuType);
  };
  public readonly menuType = toSignal(this.#menuType$, { initialValue: DESKTOP_MENU_TYPE });

  readonly #initialMenu$ = combineLatest([
    this.#partnerId$.pipe(distinctUntilChanged()),
    this.#currentPlanGroupId$,
    this.#menuType$.pipe(distinctUntilChanged()),
  ]).pipe(
    switchMap(([partnerId, planGroupId, menuType]) =>
      this.#menuWebservice.getPlanGroupMenu({ partnerId, planGroupId, menuType })
    )
  );

  readonly #menu$ = combineLatest([this.#initialMenu$, this.#updatedMenu$]).pipe(
    map(([initialMenu, updatedMenu]) => updatedMenu ?? initialMenu)
  );
  readonly #menu = toSignal(this.#menu$, { initialValue: null });

  public readonly planGroupsTabs = computed<Array<Tab>>(() => {
    const currentPlanGroupId = this.currentPlanGroupId();
    if (typeof currentPlanGroupId !== 'string') return [];

    const routes: Record<MenuType, [`../${string}`]> = {
      desktop: [`../${MENU_ROUTING.DESKTOP}`],
      mobile: [`../${MENU_ROUTING.MOBILE}`],
    } as const;

    const route = routes[this.menuType()];

    return this.planGroups().map(({ id, name }) => ({
      route,
      id: currentPlanGroupId,
      isActive: id === currentPlanGroupId,
      label: name,
      queryParams: { planGroupId: id },
    }));
  });

  public readonly mainMenuItems = computed<Array<NestedListItem>>(() => {
    const menu = this.#menu();
    const planGroupId = this.currentPlanGroupId();
    const planGroupLabel = this.currentPlanGroupLabel();
    const menuType = this.menuType();
    if (menu === null || planGroupId === null || planGroupLabel === null) return [];

    return this.#transformNavigationArrayToNestedListItems({
      navigationItems: menu.mainNavigation,
      planGroupId,
      menuType,
      planGroupLabel,
      navigationType: MAIN_NAVIGATION_TYPE,
    });
  });

  readonly secondaryMenuItems = computed<Array<NestedListItem>>(() => {
    const menu = this.#menu();
    const planGroupId = this.currentPlanGroupId();
    const planGroupLabel = this.currentPlanGroupLabel();
    const menuType = this.menuType();
    if (menu === null || planGroupId === null || planGroupLabel === null) return [];

    return this.#transformNavigationArrayToNestedListItems({
      navigationItems: menu.secondaryNavigation,
      planGroupId,
      menuType,
      planGroupLabel,
      navigationType: SECONDARY_NAVIGATION_TYPE,
    });
  });

  public readonly deleteMenuNavigationItem = (entryId: string): Observable<null> => {
    const menu = this.#menu();
    if (menu === null) return throwError(() => new Error('No menu to update'));

    return combineLatest([this.#partnerId$, this.#currentPlanGroupId$, this.#menuType$]).pipe(
      take(1),
      switchMap(([partnerId, planGroupId, menuType]) =>
        this.#menuWebservice.deleteMenuEntry({ partnerId, planGroupId, menuType, entryId })
      ),
      switchMap(() => {
        const updatedMainMenu = this.#filterMenu(menu.mainNavigation, entryId);
        const updatedSecondaryMenu = this.#filterMenu(menu.secondaryNavigation, entryId);
        const newMenu = { ...menu, mainNavigation: updatedMainMenu, secondaryNavigation: updatedSecondaryMenu };
        this.#updatedMenu$.next(newMenu);
        return of(null);
      })
    );
  };

  public readonly onListChanged = (
    list: Array<NestedListItem>,
    navigationType: NavigationType
  ): Observable<PlanGroupMenuResponse> => {
    const ordering = list.map(({ id, children }) => ({ id, children: children?.map(({ id }) => ({ id })) }));

    return combineLatest([this.#partnerId$, this.#currentPlanGroupId$, this.#menuType$]).pipe(
      take(1),
      switchMap(([partnerId, planGroupId, menuType]) =>
        this.#menuWebservice.reorderMenu({ partnerId, planGroupId, menuType, navigationType, ordering })
      ),
      tap((menu) => this.#updatedMenu$.next(menu))
    );
  };

  readonly #transformNavigationArrayToNestedListItems = ({
    navigationItems,
    planGroupId,
    menuType,
    navigationType,
    planGroupLabel,
  }: {
    navigationItems: Array<NavigationItem>;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    planGroupLabel: string;
  }): Array<NestedListItem> =>
    navigationItems.map(
      ({ label: { current: title, default: initial }, id, status, children, schedule, isMandatory }) => ({
        title,
        subTitle: this.#getSubtitle({ title, initial }),
        id,
        centerTagText: this.#getCenterTag(schedule),
        tags: [statusTag(status)],
        children: this.#transformNavigationArrayToNestedListItems({
          navigationItems: children,
          planGroupId,
          menuType,
          navigationType,
          planGroupLabel,
        }),
        actions: isMandatory
          ? [
              editAction({
                label: "Modifier l'entrée",
                link: {
                  routerLink: ['./', id],
                  queryParams: { planGroupId, planGroupLabel, menuType, navigationType },
                },
              }),
            ]
          : [
              editAction({
                label: "Modifier l'entrée",
                link: {
                  routerLink: ['./', id],
                  queryParams: { planGroupId, planGroupLabel, menuType, navigationType },
                },
              }),
              removeAction({ label: `Retirer l'entrée  ${title}` }),
            ],
      })
    );

  readonly #filterMenu = (navigationItems: Array<NavigationItem>, id: string): Array<NavigationItem> => {
    const updatedMainMenu = navigationItems.filter((item) => item.id !== id);

    const updatedMainMenuWithChildren = updatedMainMenu.map((item) => ({
      ...item,
      children: this.#filterMenu(item.children ?? [], id),
    }));

    return updatedMainMenuWithChildren;
  };

  readonly #getSubtitle = ({ title, initial }: { title: string; initial: string | null }): string => {
    if (initial === null) return '';

    if (title.toLocaleLowerCase().trim() === initial.toLocaleLowerCase().trim()) return '';

    return initial;
  };

  readonly #getCenterTag = (schedule: NavigationItemSchedule | null): string => {
    if (schedule === null) return '';

    const { active, start, end } = schedule;
    if (active === false) return '';

    const from = new Intl.DateTimeFormat('fr-FR', {
      dateStyle: 'short',
    }).format(new Date(start));

    const to = new Intl.DateTimeFormat('fr-FR', {
      dateStyle: 'short',
    }).format(new Date(end));

    return `Programmé du ${from} au ${to} `;
  };
}
