export const DESKTOP_MENU_TYPE = 'desktop' as const;
export const MOBILE_MENU_TYPE = 'mobile' as const;
export const MAIN_NAVIGATION_TYPE = 'main' as const;
export const SECONDARY_NAVIGATION_TYPE = 'secondary' as const;
export const MENU_TYPES = [DESKTOP_MENU_TYPE, MOBILE_MENU_TYPE] as const;
export const NAVIGATION_TYPES = [MAIN_NAVIGATION_TYPE, SECONDARY_NAVIGATION_TYPE] as const;
const NAVIGATION_ITEMS_STATUS = ['archived', 'draft', 'published'] as const;

export type MenuType = (typeof MENU_TYPES)[number];
export type NavigationItemStatus = (typeof NAVIGATION_ITEMS_STATUS)[number];
export type NavigationType = (typeof NAVIGATION_TYPES)[number];

export type PlanGroupMenuResponse = {
  planGroupId: string;
  menuType: MenuType;
  mainNavigation: Array<NavigationItem>;
  secondaryNavigation: Array<NavigationItem>;
};

export type NavigationItem = {
  id: string;
  status: NavigationItemStatus;
  label: NavigationItemLabel;
  children: Array<NavigationItem>;
  schedule: NavigationItemSchedule | null;
  isMandatory: boolean;
};

type NavigationItemLabel = {
  current: string;
  default: string | null;
};

export type NavigationItemSchedule = {
  active: boolean;
  start: string;
  end: string;
};

export type OrderingItemWithChildren = {
  id: string;
  children?: Array<Omit<OrderingItemWithChildren, 'children'>>;
};
